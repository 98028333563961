import React, { Fragment } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import bootstrap from "../components/bootstrap.module.css"
import SEO from "../components/seo"
import PostContent from "../components/post-content"
import Breadcrumbs from "../components/breadcrumbs"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faLinkedinIn, faYoutube } from '@fortawesome/free-brands-svg-icons';

export default function PageONas({ data }) {

  const row = data.mysqlPage
  const seo = data.seo

  const socialMedia = [
    { href: 'https://www.linkedin.com/company/fintek.pl/', class: <FontAwesomeIcon icon={faLinkedinIn} /> },
    { href: 'https://twitter.com/fintek_pl/', class: <svg className="svg-inline--fa" viewBox="0 0 24 24" ariaHidden="true"><g><path fill="currentColor" d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path></g></svg> },
    { href: 'https://www.facebook.com/fintekpl/', class: <FontAwesomeIcon icon={faFacebookF} /> },
    { href: 'https://www.youtube.com/channel/UCThB_tc44DGaGhzSMW_JZdg', class: <FontAwesomeIcon icon={faYoutube} /> },
  ];

  const teams = [
    { 
      img: 'https://static.fintek.pl/uploads/2024/01/rafal-tomaszewski.webp', 
      name: 'Rafał Tomaszewski', 
      job: 'Redaktor naczelny',
      email: 'rafal.tomaszewski@fintek.pl',
      tel: '503 460 672',
      description: 'Redaktor naczelny Fintek.pl. W serwisie od 2016 roku - wcześniej związany z branżą ubezpieczeniową. Zajmuje się szeroko rozumianym sektorem FinTech - startupy, bankowość, innowacje w płatnościach, nowinki technologiczne, zmiany w prawie i wiele innych. Prowadzi podcasty oraz przeprowadza wywiady z osobami z branży. Uczestniczy w konferencjach branżowych, a także jest odpowiedzialny za ich organizację. Współtwórca konferencji Fintech Summit Poland oraz Gali Fintech Awards, które od 2021 roku regularnie odbywają się w Warszawie. Absolwent politologii i stosunków międzynarodowych na UKSW w Warszawie.', 
      link: { href: '/redaktor/rafal-tomaszewski/', text: 'Zobacz wpisy autora', component: true, },
    },
    {
      img: "https://static.fintek.pl/uploads/2023/12/piotr-suchodolski.webp",
      name: "Piotr Suchodolski",
      job: "Partner Klang Media",
      email: "p.suchodolski@klangmedia.pl",
      tel: '732 757 275',
      description: "Piotr jest ekspertem public relations z kilkunastoletnim doświadczeniem w pracy zarówno w wewnętrznych działach komunikacji, jak i w agencjach PR. W swojej karierze pracował przy projektach dla sektora finansowego, branży dóbr luksusowych, napojów alkoholowych i dla sektora prawnego. Posiada bogate doświadczenie kooperacji z dużymi międzynarodowymi korporacjami czy spółkami notowanymi na Giełdzie Papierów Wartościowych w Warszawie. Jest absolwentem Historii na Uniwersytecie Kardynała Stefana Wyszyńskiego, Nowoczesnej Komunikacji Marketingowej na Uniwersytecie SWPS oraz Zarządzania Projektami w Szkole Głównej Handlowej.",
      link: null,
    },
  ];

  const aboutUs = [
    { title: 'Newsy', description: 'Wiesz coś ciekawego o FinTechu? Tworzysz rozwiązanie, które zmieni świat? Napisz do nas na adres: redakcja@fintek.pl. Dla sygnalistów (whistleblower) prowadzimy skrzynkę pod adresem: fintek@tutanota.com.' },
    { title: 'Wydarzenia', description: 'Przygotowujesz wydarzenie lub konferencję i zależy Ci, by ta informacja rozeszła się po rynku? Interesuje Cię patronat medialny Fintek.pl? Napisz do Rafała: rafal.tomaszewski@fintek.pl' },
    { title: 'Reklama i współpraca', description: 'Serwis Fintek.pl odwiedzają tysiące osób takich jak Ty – praktyków i ekspertów branży FinTech. Dotrzyj do nich ze swoim przekazem. Skontaktuj się z Aleksandrą: a.kwiatkowska@fintek.pl' },
  ];

  return (
    <Layout>
        <div className="about-us">

          <SEO canonical={ seo && seo.canonical ? seo.canonical : 'https://fintek.pl/' + row.post_name + '/'} title={ seo && seo.title ? seo.title : row.post_title } description={seo ? seo.description : ''} robots={seo ? [(seo.is_robots_noindex?'noindex':'index'),(seo.is_robots_nofollow?'nofollow':'follow')].join(', ') : 'index, follow'} />  

          <Breadcrumbs post={row} />
 
          <h1 className={`${bootstrap.mb3} square`}>
            <span></span>
            { row.post_title }
          </h1>
          <hr className={bootstrap.mb4} />  

          <PostContent content={row.post_content} className={'font-serif-light'} />

          <section className={`${bootstrap.my3} ${bootstrap.myMd5}`}>
            <h2 className={`h2 ${bootstrap.mb0}`}>Nasze sociale</h2>
            <div className={bootstrap.row}>
              {socialMedia.map((social, i) => (
                <div key={i} className={`social-media ${bootstrap.col4} ${bootstrap.colSmAuto} ${bootstrap.mt3} ${bootstrap.mtMd4}`}>
                  <a href={social.href} target="_blank" rel="noreferrer" className="social-media-icon">
                    {social.class}
                  </a>
                </div>
              ))}
            </div>
          </section>

          <section className={`${bootstrap.mb3} ${bootstrap.mbMd5}`}>
            <h2 className={`${bootstrap.mb3} ${bootstrap.mbMd4} h2`}>Serwis Fintek.pl - ludzie</h2>

            {teams.map((team, i) => (
              <div key={i} className={`${bootstrap.col11} ${bootstrap.colSm10} ${bootstrap.colLg11} ${bootstrap.px0} ${bootstrap.mxAuto}`}>
                <div className="team">
                  <div className="team-img-wrapper">
                    <img className={`team-img team-img-${team.name.toLowerCase().replace(' ', '-')}`} src={team.img} alt={team.name} />
                  </div>
                  <div className="team-person-wrapper">
                    <p className="team-name">{team.name}</p>
                    <p className="team-job">{team.job}</p>
                  </div>
                  <div className="team-description">
                    {team.description}
                  </div>
                  <div className="team-footer">
                    <div className={`${bootstrap.row} ${bootstrap.noGutters}`}>
                      <p className={`${bootstrap.colSmAuto} ${bootstrap.mr3}`}>{team.email}</p>
                      {team.tel && <p className={bootstrap.colSmAuto}>tel. {team.tel}</p>}
                      {team.link && <div className={bootstrap.col12}>
                        {team.link.component 
                          ? <AniLink cover to={team.link.href} className={`${bootstrap.fontWeightBold} line`}>{team.link.text}</AniLink>
                          : <a href={team.link.href} className={`${bootstrap.fontWeightBold} line`}>{team.link.text}</a>}
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </section>

          <section className={`${bootstrap.mb3} ${bootstrap.mbMd5}`}>
            <h2 className={`${bootstrap.mb3} ${bootstrap.mbMd4} h2`}>Twórz z nami najlepszy serwis o Fintechu w Polsce!</h2>
            {aboutUs.map((fintek, i) => (
              <Fragment key={i}>
                <p className={`${bootstrap.fontWeightBold} line`}>{fintek.title}</p>
                <p className={bootstrap.my3}>{fintek.description}</p>
              </Fragment>
            ))}
          </section>
        </div>
 
    </Layout>
  )
  
}

export const query = graphql`
query PageQueryONas($slug: String, $id: Int) {
  mysqlPage: mysqlPage(post_name: {eq: $slug}) {
    id
    post_date
    post_title
    post_content
    post_name
  }
  seo: mysqlIndexable(object_type: {eq: "post"}, object_id: {eq: $id}) {
    title
    description
    object_id
    object_type
    permalink
    breadcrumb_title
    canonical
    is_robots_noindex
    is_robots_nofollow
  }    
}
`
